<template>
  <iframe
    id="frame"
    class="frame"
    :src="(workaroundCategory || category).frameUrl"
    allowtransparency="true"
  />
</template>

<script>
  import {mapGetters} from 'vuex'
  import store from "@/store";

  export default {
    inheritAttrs: false,
    props: [
      'layout',
      'category',
      'isDigitalSignage',
    ],
    computed: {
      ...mapGetters({
        restURI: 'config/restURI'
      })
    },
    data() {
      return {
        // TODO: Should be deleted after REST-API has been adjusted
        // TODO: for loading properties directly (frameUrl)
        workaroundCategory: null,
      }
    },
    async created() {
      if (this.splitviewMode) {
        this.workaroundCategory = await this.$api.findCategory(this.category.id)
      }
    },
    mounted() {
      let frameElement = document.getElementById('frame')
      if (frameElement) {
        let slugId = this.$helper.extractIdFromSlug(this.$route.params.slug)
        let auth = store.getters['auth/authCode']
        if (!auth) {
          let username = 'guest'
          let password = 'guest'
          auth = btoa(username + ':' + password)
        }
        setTimeout(() => {
          frameElement.contentWindow.postMessage(JSON.stringify({
            cmd: 'getRestData',
            rest_url: this.restURI,
            category_id: this.category.id,
            project_id: slugId,
            auth: auth,
          }), '*')
        }, 250)
      }
    },
    inject: [
      '$api',
      '$helper',
    ]
  }
</script>

<style lang="scss" scoped>
  .frame {
    @apply
      flex
      w-full
      h-full
      items-center
      justify-center;
  }
</style>